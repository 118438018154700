@charset 'UTF-8';
.footer {
	#to-top {
		position: fixed;
		bottom: 30px;
		right: 30px;
		z-index: 9998;
		display: none;
		@include SP {
			bottom: 10px;
			right: 10px;
		}
		a {
			display: block;
		}
	}
	&__instagram {
		background: #000;
		padding: 0 0 200px;
		@include SP {
			padding-bottom: 60px;
		}
		&--logo {
			text-align: center;
			padding: 70px 0;
			@include SP {
				padding: 50px 0 46px;
			}
			img {
				margin: 0 0 10px;
				@include SP {
					width: 60px;
					margin: 0 0 20px;
				}
			}
			.tit {
				color: #fff;
				@include ffBebas;
				@include font(30,32);
				margin: 0 0 15px;
				@include SP {
					@include font(24,25);
				}
			}
			.des {
				a {
					display: inline-block;
					color: #3298F0;
					@include font(12,12);
					@include SP {
						@include font(10,10);
					}
				}
			}
		}
		&--list {
			@include flexbox();
			@include flex-wrap(wrap);
			overflow: hidden;
			border-top: 1px solid #fff;
			border-bottom: 1px solid #fff;
			background: #fff;
			li {
				width: 33.333%;
				position: relative;
				border-bottom: 1px solid #fff;
		    border-right: 1px solid #fff;
		    &:nth-of-type(3n) {
		      border-right: none;
		    }
		    &:last-child,
		    &:nth-last-child(2),
		    &:nth-last-child(3) {
		      border-bottom: none;
		    }
				a {
					display: block;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					@include aspect-ratio(426,426);
					img {
						display: none;
					}
				}
			}
		}
	}
	&__about {
		padding: 100px 0;
		@include SP {
			padding: 0 0 80px;
		}
		.box-about {
			@include flexbox();
			@include SP {
				@include flex-direction(column);
			}
			&-img {
				width: 480px;
				@include flex(0 0 auto);
				@include SP {
					width: 100%;
				}
				img {
					width: 100%;
					display: block;
				}
			}
			&-info {
				padding: 20px 0 0 60px;
				@include SP {
					padding-left: 20px;
					padding-top: 42px;
					border-top: 1px solid #000;
				}
				.name-store {
					@include ffBebas;
					@include font(32,35);
					margin: 0 0 20px;
				}
				&-item {
					margin: 0 0 15px;
					.ttl {
						@include ffBebas;
						@include font(24,25);
						margin: 0 0 5px;
					}
					.time {
						@include ffBebas;
						@include font(18,30);
						@include ANDR {
							@include font(16);
						}
					}
					.address {
						font-weight: 700;
						@include font(14,16);
						margin: 5px 0 0;
					}
				}
				.global-btn {
					margin: 8px 0 0;
				}
			}
		}
	}
	&__sitemap {
		background: #000;
		padding: 90px 0 25px;
		@include SP {
			padding-left: 20px;
			padding-top: 50px;
			padding-bottom: 20px;
		}
		.box-sitemap {
			@include flexbox();
			.links {
				&:not(:last-child) {
					margin: 0 70px 0 0;
				}
				li {
					padding: 9px 0;
					a {
						display: inline-block;
						color: #fff;
						font-weight: 500;
						@include font(12,15);
					}
				}
			}
		}
		.copyright {
			color: #fff;
			font-family: Helvetica, Arial, sans-serif;
			@include font(10,10);
			margin: 145px 0 0;
			@include SP {
				text-align: center;
				margin: 112px 0 0 -20px;
			}
		}
	}
}