@charset "UTF-8";
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
p {
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input, textarea {
  font-size: 100%;
  margin: 0;
  resize: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
  width: 100%;
}
dl, dt, dd, th, td {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  /*font-size: 100%;*/
  font-weight: normal;
  margin: 0;
  padding: 0;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, dialog {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
img {
  border: 0;
  height:auto;
  margin: 0;
  max-width:100%;
  padding: 0;
  vertical-align: middle;
}
table img {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {display: block;}
nav ul {list-style: none;}
*, *:before, *:after {
  @include add_prefix(box-sizing, border-box);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus {outline: none!important;}
label, select, button {cursor: pointer;}

// Remove select appearance for IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select::-ms-expand {
    display: none;
  }
}
@media screen\0 {
  select::-ms-expand {
    display: none;
  }
}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
  outline: none;
  transition:0.3s;
  @include PC {
    &:hover {
      color: #aaa;
      opacity: 0.75;
      text-decoration: none;
    }
  }
}
a:link, a:visited, a:active {
  color: $colortext;
  text-decoration: none;
}
/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
.ffN {@include ffN;}
.ffM {@include ffM;}
.ffYG {@include ffYG;}
.ffYM {@include ffYM;}
.ffNoto {@include ffNoto;}
.ffBebas {@include ffBebas;}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
html {
  font-size: 62.5%;
  @include add_prefix(text-size-adjust, 100%);
}
body {
  @include ffNoto;
  @include font(12,24);
  color: $colortext;
  height: 100%;
  margin: 0;
  min-width: 1280px;
  padding: 0;
  width: 100%;
  word-break: break-word;
  @include SP {
    min-width: 100%;
  }
}

/*-----------------------------------------------------------
Container
------------------------------------------------------------*/
#wrap {
  margin: 60px 0 0;
  overflow: hidden;
}
.w-inner {
  margin: 0 auto;
  max-width: 1080px;
}
/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.sp, .SP {
  @include min-screen(560px) {display: none!important;}
}

.pc, .PC {
  @include max-screen(559px) {display: none!important;}
}

.caret {
  height: 20px;
  position: absolute;
  right: 20px;
  width: 10px;
  @include rotate(-90);
  @include transition_c(0.4s);
  &:before {
    background: url('../img/common/ico_arrow_left_b.svg') 0 0/100% auto no-repeat;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
  }
  .is-active & {
    @include rotate(90);
  }
}

.owl-carousel {
  .owl-nav {
    left: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    @include translate(0,-50%);
    @include flexbox();
    @include justify-content(space-between);
    button {
      height: 30px;
      position: relative;
      width: 15px;
      span {
        display: none;
      }
      &.owl-prev {
        left: 20px;
        &:before {
          background: url('../img/common/ico_arrow_left_w.svg') 0 0/100% auto no-repeat;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
      &.owl-next {
        right: 20px;
        &:before {
          background: url('../img/common/ico_arrow_right_w.svg') 0 0/100% auto no-repeat;
          content: '';
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
        }
      }
    }
  }
  .owl-dots {
    bottom: 40px;
    font-size: 0;
    left: 0;
    line-height: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 10;
    @include SP {
      bottom: 20px;
    }
    button.owl-dot {
      background: transparent;
      border: 1px solid #fff;
      height: 8px;
      margin: 0 4px;
      width: 8px;
      @include border-radius(100%);
      @include transition_c(.3s);
      @include SP {
        width: 6px;
        height: 6px;
        margin: 0 3px;
      }
      span {
        display: block;
      }
      &.active {
        background: #fff;
      }
    }
  }
}

.global-btn {
  border: 1px solid #000;
  display: inline-block;
  max-width: 140px;
  padding: 8px 20px 8px 10px;
  position: relative;
  text-align: center;
  width: 100%;
  @include ffBebas;
  @include font(18,20);
  &:after {
    content: '＞';
    display: inline-block;
    margin-top: 2px;
    position: absolute;
    right: 10px;
    top: 50%;
    @include ffBebas;
    @include font(18,18);
    @include translate(0,-50%);
  }
}

.global-title {
  height: 240px;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
  @include flex-direction(column);
  border-top: 1px solid #000;
  @include SP {
    height: 140px;
    padding: 18px 0 0;
  }
  &-tit {
    @include ffBebas;
    @include font(48,52);
    @include SP {
      @include font(30,32)
    }
  }
  &-des {
    @include font(12,16);
    display: block;
    font-weight: 500;
    margin: 20px 0 0;
    position: relative;
    @include SP {
      @include font(10,16);
      margin: 15px 0 0;
    }
  }
  .icon {
    bottom: -50px;
    display: block;
    left: 0;
    position: absolute;
    text-align: center;
    text-align: center;
    width: 100%;
    @include SP {
      bottom: initial;
      left: -65px;
      top: -45px;
    }
  }
}


.global-box-product {
  @include flexbox();
  @include flex-wrap(wrap);
  border-top: 1px solid #000;
  position: relative;
  &:after {
    border-bottom: 1px solid #000;
    content: '';
    display: block;
    height: 1px;
    margin: -1px 0 0;
    width: 100%;
  }
  .item-wrap {
    display: block;
  }
  &-item {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    position: relative;
    width: 25%;
    @include SP {
      width: 50%;
    }
    @include PC {
      &:nth-of-type(4n) {
        border-right: none;
      }
    }
    @include SP {
      &:nth-of-type(2n) {
        border-right: none;
      }
    }
  }
  &-img {
    @include aspect-ratio(320,384);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &-description {
      @include font(10,10,50);
      background: #fff;
      border: 1px solid #000;
      bottom: 10px;
      font-weight: 500;
      left: 15px;
      padding: 7px 18px;
      position: absolute;
      z-index: 1;
      @include SP {
        @include font(8,8,0);
        bottom: 5px;
        left: 10px;
        padding: 3px;
      }
    }
  }
  &-badge {
    border: 1px solid #000;
    border-top: none;
    max-width: 136px;
    padding: 8px;
    pointer-events: none;
    position: absolute;
    right: -1px;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1;
    @include font(25,25);
    @include ffBebas;
    @include SP {
      @include font(16,16);
      max-width: 80px;
      padding: 4px;
    }
    &.sale {
      background: #FF3C00;
    }
    &.soldout {
      background: #000;
      color: #fff;
    }
  }
  &-info {
    padding: 10px 15px 20px;
    @include SP {
      padding: 5px 10px;
    }
  }
  &-title {
    @include ffBebas;
    @include font(16,17);
    margin: 0 0 5px;
    @include SP {
      @include font(11,12);
    }
  }
  &-price {
    @include flexbox();
    @include align-items(baseline);
    .price {
      @include ffBebas;
      @include font(16,17);
      display: inline-block;
      @include SP {
        @include font(11,12);
      }
      &.strikethrough {
        text-decoration: line-through;
      }
    }
    .currency {
      @include font(10,15);
      display: inline-block;
      font-weight: 500;
      @include SP {
        @include font(8,16);
      }
    }
    .price-regular,
    .price-fixed {
      line-height: 0;
    }
    .price-fixed {
      margin: 0 10px 0 0;
      @include SP {
        margin-right: 5px;
      }
    }
    .price-discount {
      color: #FF3C00;
    }
  }
}

.global-category-list {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(center);
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  padding: 40px;
  @include SP {
    padding: 20px 0 30px;
  }
  li {
    width: 10%;
    @include SP {
      width: 20%;
    }
    a {
      display: block;
      padding: 13px 0 18px;
      @include SP {
        padding: 8px 0 10px;
      }
      figure {
        text-align: center;
        img {
          display: block;
          width: 100%;
        }
        figcaption {
          display: block;
          margin: 10px 0 0;
          padding: 0 10px;
          @include ffBebas;
          @include font(14,16);
          @include SP {
            @include font(11,12);
            margin: 5px 0 0;
            padding: 0 5px;
          }
          @include ANDR {
            @include font(10);
          }
        }
      }
    }
  }
}

.global-product-row {
  @include flexbox();
  @include flex-wrap(wrap);
  // border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  .item-wrap {
    display: block;
  }
  &-item {
    position: relative;
    border-bottom: 1px solid #000;
    // &:not(:last-child) {
    //   &:after {
    //     background: #000;
    //     content: '';
    //     display: block;
    //     height: 100%;
    //     pointer-events: none;
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     width: 1px;
    //     z-index: 1;
    //   }
    // }
    &:not(:nth-of-type(6n)) {
      &:after {
        background: #000;
        content: '';
        display: block;
        height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        z-index: 1;
      }
    }
  }
  &.col-6 {
    .global-product-row-item {
      width: 16.66%;
      border-bottom: none;
      @include SP {
        width: 50%;
      }
      &:nth-child(n + 3){
        // @include SP {
        //   border-top: 1px solid #000;
        // }
      }
      &:nth-child(n + 7){
        @include PC {
          border-top: 1px solid #000;
        }
      }
      &:nth-of-type(2n) {
        @include SP {
          &:after {
            display: none;
          }
        }
      }
      &:last-child,
      &:nth-last-child(2) {
        @include SP {
          border-bottom: none;
        }
      }
    }
  }
  &.col-3 {
    .global-product-row-item {
      width: 33.33%;
      @include SP {
        border-bottom: none;
      }
    }
  }
  &-img {
    @include aspect-ratio(212,256);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.product-ranking {
    counter-reset: section;
    .global-product-row-item {
      counter-increment: section;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .global-product-row-ranking {
          &:before {
            background: #000;
            color: #fff;
          }
        }
      }
    }
  }
  &-ranking {
    border: 1px solid #000;
    display: block;
    height: 40px;
    left: -1px;
    pointer-events: none;
    position: absolute;
    top: -1px;
    width: 40px;
    z-index: 1;
    @include SP {
      height: 35px;
      width: 35px;
    }
    &:before {
      content: counter(section);
      display: block;
      height: 100%;
      text-align: center;
      width: 100%;
      @include font(22,22);
      @include ffBebas;
      @include flexbox();
      @include justify-content(center);
      @include align-items(center);
      @include SP {
        @include font(20,20);
      }
    }
  }
  &.related-items {
    .global-product-row-item {

    }
  }
}

.global-categories-group {
  background: #fff;
  border-bottom: 1px solid #000;
  padding: 18px 30px 17px;
  @include SP {
    padding: 0;
  }
  &.have-fixed {
    left: 0;
    min-width: 1280px;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 2000;
    @include SP {
      min-width: 100%;
    }
  }
  &-title {
    @include font(24,24);
    @include ffBebas;
    padding: 18px 30px 17px;
  }
  &-list-wrap {
    @include PC {
      display: block !important;
    }
    @include SP {
      display: none;
      height: 100%;
      overflow-y: auto;
    }
  }
  &-list {
    @include flexbox();
    @include SP {
      @include flex-direction(column);
      border-top: 1px solid #000;
      padding: 10px 30px 0;
    }
    li {
      @include SP {
        padding: 10px 0;
      }
      a {
        display: inline-block;
        padding: 0 10px;
        @include font(14,16);
        @include ffBebas;
        @include SP {
          @include font(18,22);
          padding: 0;
        }
      }
    }
  }
}

.global-categories-filter {
  @include flexbox();
  @include flex-wrap(wrap);
  height: 120px;
  @include SP {
    height: 50px;
  }
  &.have-cate-group {
    margin: 60px 0 0;
  }
  &-name {
    border-right: 1px solid #000;
    padding: 20px;
    width: 50%;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include ffBebas;
    @include font(40,43);
    @include SP {
      display: none;
    }
  }
  &-sortby {
    width: 50%;
    @include SP {
      width: 100%;
    }
    .sortby-list {
      @include flexbox();
      @include flex-wrap(wrap);
      height: 100%;
      li {
        width: 33.333%;
        &:not(:last-child) {
          border-right: 1px solid #000;
        }
        a {
          @include flexbox();
          color: #BCBCBC;
          font-weight: 500;
          height: 100%;
          @include justify-content(center);
          @include align-items(center);
          @include font(12,16);
          &.is-active {
            color: #000;
          }
        }
      }
    }
  }
}

.global-categories-search {
  height: 120px;
  @include flexbox();
  @include flex-wrap(wrap);
  @include SP {
    @include flex-direction(column);
    height: auto;
    padding: 58px 0 35px;
  }
  &.have-cate-group {
    margin: 60px 0 0;
  }
  &-keyword {
    border-right: 1px solid #000;
    padding: 0 15px 20px;
    width: 50%;
    @include flexbox();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    @include SP {
      border-right: none;
      padding-bottom: 38px;
      width: 100%;
    }
    .ttl {
      @include ffBebas;
      @include font(12,13);
      @include SP {
        @include font(16,17);
      }
    }
    .txt-keyword {
      @include font(12,16);
      margin: 9px 0 0;
    }
  }
  &-results {
    width: 50%;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include ffBebas;
    @include font(40,43);
    @include SP {
      @include font(24,25);
      width: 100%;
    }
  }
}

.global-pagenavi {
  border-bottom: 1px solid #000;
  height: 120px;
  @include flexbox();
  @include justify-content(space-between);
  @include SP {
    padding: 0 5px;
  }
  .page {
    @include ffBebas;
    @include font(24,25);
  }
  .first-page,
  .last-page {
    width: 25%;
    .page {
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      height: 100%;
      @include SP {
        @include font(12,13);
      }
      span {
        display: inline-block;
        position: relative;
        &:before {
          content: '';
          height: 20px;
          position: absolute;
          top: 1px;
          width: 10px;
          @include SP {
            top: -5px;
          }
        }
      }
    }
  }
  .first-page {
    border-right: 1px solid #000;
    @include SP {
      border-right: none;
    }
    .page {
      span {
        &:before {
          background: url('../img/common/ico_arrow_left_b.svg') 0 0/10px 20px no-repeat;
          left: -40px;
          @include SP {
            left: -20px;
          }
        }
      }
    }
  }
  .last-page {
    .page {
      span {
        &:before {
          background: url('../img/common/ico_arrow_right_b.svg') 0 0/10px 20px no-repeat;
          right: -40px;
          @include SP {
            right: -20px;
          }
        }
      }
    }
  }
  .pager-main {
    border-right: 1px solid #000;
    width: 50%;
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    @include SP {
      border-right: none;
    }
    .pages {
      padding: 0 30px;
      @include SP {
        padding: 0 40px;
      }
    }
    .prev {
      .page {
        padding-right: 34px;
        position: relative;
        @include SP {
          padding-right: 0;
          @include font(12,13);
          span {
            display: inline-block;
            opacity: 0;
            text-indent: -9999px;
          }
        }
        &:before {
          background: url('../img/common/ico_arrow_left_b.svg') 0 0/10px 20px no-repeat;
          content: '';
          height: 20px;
          position: absolute;
          right: 0;
          top: 3px;
          width: 10px;
          @include SP {
            background: url('../img/common/ico_arrow_left_b_thick.svg') 0 0/12px 22px no-repeat;
            height: 22px;
            left: 0;
            right: initial;
            top: -6px;
            width: 12px;
          }
        }
      }
    }
    .next {
      .page {
        padding-left: 34px;
        position: relative;
        @include SP {
          @include font(12,13);
          padding-left: 0;
          span {
            display: inline-block;
            opacity: 0;
            text-indent: -9999px;
          }
        }
        &:before {
          background: url('../img/common/ico_arrow_right_b.svg') 0 0/10px 20px no-repeat;
          content: '';
          height: 20px;
          left: 0;
          position: absolute;
          top: 3px;
          width: 10px;
          @include SP {
            background: url('../img/common/ico_arrow_right_b_thick.svg') 0 0/12px 22px no-repeat;
            height: 22px;
            left: initial;
            right: 0;
            top: -6px;
            width: 12px;
          }
        }
      }
    }
  }
}

/*-----------------------------------------------------------
PAGE TOP
------------------------------------------------------------*/

.top {
  &__mainvisual {
    @include flexbox();
    @include flex-wrap(wrap);
    border-bottom: 1px solid #000;
    @include SP {
      @include flex-direction(column);
    }
    &--slideshow {
      width: 50%;
      @include SP {
        width: 100%;
      }
      a {
        display: block;
      }
    }
    &--banner {
      width: 50%;
      @include SP {
        width: 100%;
      }
      .box-bnr {
        @include flexbox();
        @include flex-wrap(wrap);
        position: relative;
        li {
          width: 50%;
          a {
            display: block;
            img {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/*-----------------------------------------------------------
PAGE PRODUCT DETAIL
------------------------------------------------------------*/
.product-detail-box {
  border-bottom: 1px solid #000;
  &.have-cate-group {
    margin: 60px 0 0;
  }
  &-slideshow {
    border-bottom: 1px solid #000;
    .owl-item {
      border-left: 1px solid #000;
      &.active {
        &.b02{
          border-left: 1px solid transparent;
          @include SP {
            border: none;
          }
        }
      }
      @include SP {
        border: none;
      }
    }
    .product-img {
      position: relative;
      z-index: 1;
      img {
        display: block;
        width: 100%;
      }
    }
    .owl-nav {
      button {
        &.owl-prev {
          left: 40px;
          @include SP {
            left: 20px;
          }
          &:before {
            background: url('../img/common/ico_arrow_left_b.svg') 0 0/100% auto no-repeat;
          }
        }
        &.owl-next {
          right: 40px;
          @include SP {
            right: 20px;
          }
          &:before {
            background: url('../img/common/ico_arrow_right_b.svg') 0 0/100% auto no-repeat;
          }
        }
      }
    }
    .owl-dots {
      bottom: 20px;
      button.owl-dot {
        border: 1px solid #000;
        &.active {
          background: #000;
        }
      }
    }
  }
  &-info {
    @include flexbox();
    @include flex-wrap(wrap);
    &-description {
      padding: 40px;
      width: 66.66%;
      @include SP {
        width: 100%;
        padding: 34px 20px 0;
      }
    }
    &-caption {
      @include font(12,16);
      border: 1px solid #000;
      display: inline-block;
      font-weight: 500;
      margin: 0 0 10px;
      padding: 4px 10px;
    }
    &-tit {
      @include ffBebas;
      @include font(36,38);
      margin: 0 0 5px;
      @include SP {
        @include font(24,26);
        margin-bottom: 10px;
      }
    }
    &-price {
      @include flexbox();
      @include align-items(baseline);
      margin: 0 0 12px;
      .price {
        @include ffBebas;
        @include font(30,32);
        display: inline-block;
        @include SP {
          @include font(24,26);
        }
        &.strikethrough {
          text-decoration: line-through;
        }
      }
      .currency {
        @include font(14,16);
        display: inline-block;
        font-weight: 500;
        @include SP {
          @include font(12,16);
        }
      }
      .price-regular,
      .price-fixed {
        line-height: 0;
      }
      .price-fixed {
        margin: 0 10px 0 0;
        @include SP {
          margin-right: 5px;
        }
      }
      .price-discount {
        color: #FF3C00;
      }
    }
    &-point {
      @include flexbox();
      @include align-items(center);
      margin: 0 0 28px;
      @include SP {
        margin-bottom: 30px;
      }
      .point {
        border: 1px solid #FF3C00;
        color: #FF3C00;
        display: inline-block;
        font-weight: 500;
        height: 100%;
        margin: 0 10px 0 0;
        padding: 5px 10px 6px;
        @include font(12,12);
        @include flex(0 0 auto);
        @include SP {
          margin-right: 15px;
        }
      }
    }
    &-text {
      @include SP {
        border-top: 1px solid #000;
        margin: 0 -20px;
      }
      .tit {
        @include font(14,16);
        font-weight: 500;
        padding: 22px 20px;
        border-bottom: 1px solid #000;
        @include PC {
          display: none !important;
        }
        a {
          cursor: default;
          display: inline-block;
          position: relative;
        }
        .caret {
          right: -30px;
        }
      }
      .box-text {
        @include SP {
          display: none;
          padding: 40px 10px;
          border-bottom: 1px solid #000;
        }
        p {
          @include font(12,24,50);
          font-weight: 500;
        }
      }
    }
    &-specs {
      padding: 40px 0;
      width: 33.33%;
      border-left: 1px solid #000;
      @include SP {
        width: 100%;
        padding: 14px 0 20px;
        border-left: none;
      }
    }
    &-option-tbl {
      // border: 1px solid #D2D2D7;
      border-right: 1px solid #D2D2D7;
      border-bottom: 1px solid #D2D2D7;
      margin: 0 auto 40px;
      max-width: 355px;
      width: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include SP {
        margin-bottom: 14px;
      }
      @include ANDR {
        width: 95%;
      }
      #option_tbl {
        th {
          border-left: 1px solid #D2D2D7;
          border-top: 1px solid #D2D2D7;
          @include ffBebas;
          @include font(12,12);
          height: 30px;
          position: relative;
          text-align: center;
          vertical-align: middle;
          // @include flexbox();
          // @include align-items(center);
          // @include justify-content(center);
        }
        td {
          border-left: 1px solid #D2D2D7;
          border-top: 1px solid #D2D2D7;
          height: 30px;
          position: relative;
          text-align: center;
          vertical-align: middle;
          // @include flexbox();
          // @include align-items(center);
          // @include justify-content(center);
          font-family: Helvetica, Arial, sans-serif;
          @include font(11,12);
          input[type="radio"] {
            // left: 0;
            // opacity: 0;
            // position: absolute;
            // top: 0;
          }
          label {
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);
            height: 100%;
            width: 100%;
            display: none!important;
            .table_price {
              display: none!important;
            }
            span {
              display: inline-block;
              // height: 12px;
              position: relative;
              // width: 12px;
              &:before {
                background: #D2D2D7;
                content: '';
                height: 12px;
                left: 0;
                position: absolute;
                top: 0;
                width: 12px;
                @include border-radius(100%);
              }
              &:after {
                background: #FF3C00;
                content: '';
                height: 6px;
                left: 3px;
                opacity: 0;
                position: absolute;
                top: 3px;
                width: 6px;
                @include border-radius(100%);
              }
            }
          }
          input[type="radio"]:checked + label {
            span {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }


      .option-tbl-row {
        @include flexbox();
        @include flex-wrap(wrap);
        &:not(:last-child) {
          border-bottom: 1px solid #D2D2D7;
        }
      }
      .option-tbl-col {
        font-family: Helvetica, Arial, sans-serif;
        height: 30px;
        position: relative;
        text-align: center;
        width: 16.66%;
        @include font(11,12);
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        &:not(:last-child) {
          border-right: 1px solid #D2D2D7;
        }
        &.thead {
          @include ffBebas;
          @include font(12,12);
        }
        input[type="radio"] {
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
        }
        label {
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          height: 100%;
          width: 100%;
          span {
            display: inline-block;
            height: 12px;
            position: relative;
            width: 12px;
            &:before {
              background: #D2D2D7;
              content: '';
              height: 12px;
              left: 0;
              position: absolute;
              top: 0;
              width: 12px;
              @include border-radius(100%);
            }
            &:after {
              background: #FF3C00;
              content: '';
              height: 6px;
              left: 3px;
              opacity: 0;
              position: absolute;
              top: 3px;
              width: 6px;
              @include border-radius(100%);
            }
          }
        }
        input[type="radio"]:checked + label {
          span {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
    &-cart {
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
      height: 60px;
      @include flexbox();
    }
    &-qty {
      border-right: 1px solid #000;
      font-weight: 500;
      text-align: center;
      width: 84px;
      @include flex(0 0 auto);
      @include font(12,16);
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      @include SP {
        width: 60px;
      }
    }
    &-number {
      border-right: 1px solid #000;
      width: 84px;
      @include flex(0 0 auto);
      position: relative;
      @include SP {
        width: 100px;
      }
      .ui-widget.ui-widget-content {
        border: none;
        @include border-radius(0);
        height: 100%;
      }
      .ui-spinner a.ui-spinner-button {
        width: 30px;
        height: 30px;
        border: none;
        background: #fff;
        @include SP {
          width: 48px;
        }
      }
      .ui-button .ui-icon {
        background: url('../img/common/ico_arrow_left_b_thick.svg') 0 0/8px 14px no-repeat;
        width: 8px;
        height: 14px;
        margin-left: -5px;
        margin-top: -6px;
        &.ui-icon-triangle-1-n {
          @include rotate(90);
        }
        &.ui-icon-triangle-1-s {
          @include rotate(-90);
          margin-top: -8px;
        }
      }
      .ui-widget input,
      input {
        border: none;
        font-family: Arial, sans-serif;
        height: 100%;
        resize: none;
        width: 100%;
        @include font(16,16);
        padding: 0 18px;
        margin: 0;
      }
    }
    &-button-cart {
      background: #1592E6;
      @include flex(1);
      button {
        background: none;
        border: none;
        outline: 0;
        color: #fff;
        @include font(15,16,50);
        font-weight: 500;
        @include ffNoto;
        width: 100%;
        height: 100%;
        @include transition_c(.3s);
        @include PC {
          &:hover {
            opacity: .8;
          }
        }
      }
      input {
        background: none;
        border: none;
        outline: 0;
        color: #fff;
        @include font(15,16,50);
        font-weight: 500;
        @include ffNoto;
        width: 100%;
        height: 100%;
        @include transition_c(.3s);
        @include PC {
          &:hover {
            opacity: .8;
          }
        }
      }
    }
    &-card {
      padding: 20px 0 0 45px;
      @include SP {
        padding: 20px 7px 0;
      }
      .list-card {
        @include flexbox();
        max-width: 360px;
        width: 100%;
        @include flex-wrap(wrap);
        @include SP {
          @include justify-content(center);
          max-width: 100%;
        }
        li {
          width: 16.66%;
          img {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
}
