@charset 'UTF-8';
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9997;
	height: 60px;
	background: #fff;
	border-bottom: 1px solid #000;
	@include flexbox();
	@include flex-wrap(wrap);
	min-width: 1280px;
	@include SP {
		min-width: 100%;
	}
	&__logo {
		width: 50%;
		border-right: 1px solid #000;
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		padding: 0 20px;
		@include SP {
			padding: 0 15px;
			border-right: none;
			@include justify-content(flex-start);
		}
		&--btn-menu {
			display: none;
			border: none;
			outline: none;
			cursor: pointer;
			background: transparent;
			width: 24px;
			height: 13px;
			position: relative;
			margin: 0 15px 0 0;
			@include transition_c(0.3s);
			.burger-icon {
				@include burger(24px,1px,5px,#000);
			}
			&.is-active {
				.burger-icon {
					@include burger-to-cross;
				}
			}
			@include SP {
				display: block;
			}

		}
		&--img-logo {
			text-align: center;
			a {
				line-height: 0;
				display: block;
				img {
					@include SP {
						max-width: 110px;
						height: 12px;
						width: 100%;
					}
				}
			}
		}
	}
	&__info {
		width: 50%;
		@include flexbox();
		@include flex-wrap(wrap);
		&--box-search {
			width: 50%;
			border-right: 1px solid #000;
			@include SP {
				width: 100%;
				border-right: none;
				border-bottom: 1px solid #000;
				position: absolute;
				top: 60px;
				left: 0;
				display: none;
				z-index: 1000;
			}
			.form-search {
				width: 100%;
				height: 59px;
				position: relative;
				@include SP {
					&:before {
						content: '';
						background: url('../img/common/ico_search.svg') center left/20px 20px no-repeat;
						width: 20px;
						height: 20px;
						position: absolute;
						top: 50%;
						left: 20px;
						margin-top: -10px;
					}
				}
				input {
					width: 100%;
					height: 100%;
					@include font(10);
					font-weight: 500;
					-webkit-appearance: none;
					border: none;
					@include border-radius(0);
					box-shadow: none;
					padding: 0 45px 0 20px;
					@include SP {
						padding: 0 60px;
					}
					&::-webkit-input-placeholder {
		              color: #BCBCBC;
		              @include font(10);
		              @include SP {
		              	@include font(12);
		              }
		            }
		            &:-moz-placeholder {
		              color: #BCBCBC;
		              @include font(10);
		              @include SP {
		              	@include font(12);
		              }
		            }
		            &::-moz-placeholder {
		              color: #BCBCBC;
		              @include font(10);
		              @include SP {
		              	@include font(12);
		              }
		            }
		            &:-ms-input-placeholder {
		              color: #BCBCBC;
		              @include font(10);
		              @include SP {
		              	@include font(12);
		              }
		            }
				}
				button {
					line-height: 0;
					position: absolute;
					right: 20px;
					top: 50%;
					margin-top: -10px;
					background: none;
					outline: 0;
					border: none;
					padding: 0;
					@include SP {
						width: 59px;
						text-align: center;
						height: 100%;
						top: 0;
						@include translate(0,0);
						margin-top: 0;
						right: 0;
						border-left: 1px solid #000;
					}
					img {
						@include SP {
							display: none;
						}
					}
					span {
						display: none;
						@include SP {
							display: block;
							@include ffBebas;
							@include font(18,18);
						}
					}
					@include PC {
						&:hover {
							opacity: .8;
						}
					}
				}
			}
		}
		&--box-button {
			width: 50%;
			padding: 0 20px;
			@include flexbox();
			@include align-items(center);
			@include justify-content(center);
			@include SP {
				width: 100%;
				padding: 0 15px 0 0;
				@include justify-content(flex-end);
			}
			.box-links {
				@include flexbox();
				overflow: hidden;
				margin: 0 -10px;
				@include SP {
					display: none;
				}
				li {
					@include flex(0 0 auto);
					a {
						@include font(10,15);
						font-weight: 500;
						display: inline-block;
						padding: 0 10px;
					}
				}
			}
			.box-btn {
				@include flexbox();
				@include align-items(flex-end);
				overflow: hidden;
				margin: 0 -10px;
				padding: 0 0 0 20px;
				@include SP {
					padding: 0;
					margin: 0 -5px;
				}
				&-item {
					padding: 0 10px;
					@include SP {
						padding: 0 8px;
					}
					@include ANDR {
						padding: 0 5px;
					}
					a {
						display: block;
						line-height: 0;
					}
					&.activity {
						a {
							// display: none;
							&.is-active {
								display: block;
								position: relative;
							}
						}
					}
				}
				&-cart {
					.item-num {
						@include font(11,11);
						@include ffBebas;
						position: absolute;
						width: 100%;
						left: 50%;
						bottom: 2px;
						color: #fff;
						@include translate(-50%,0);
						text-align: center;
					}
				}
			}
		}
	}
	&__gnavi {
		@include PC {
			display: none !important;
		}
		position: fixed;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		top: 60px;
		left: 0;
		z-index: 9999;
		@include transition_c(.5s);
		@include translate(-100%,0);
		padding: 0 0 40px;
		&.is-open {
			@include translate(0,0);
		}
		&--menu {
			max-width: 305px;
			width: 100%;
			border-right: 1px solid #000;
			background: rgba(255,255,255,0.9);
			padding: 0 0 40px;
			height: 100%;
			>li {
				>a {
					padding: 20px 30px;
					display: block;
					@include font(14,20);
					font-weight: 500;
					border-bottom: 1px solid #000;
				}
				&.has-child {
					position: relative;
					.caret {
						position: absolute;
						right: 30px;
						@include rotate(-90);
						@include transition_c(0.4s);
					}
					&.is-active {
						.caret {
							@include rotate(90);
						}
					}
				}
				.child-menu {
					padding: 16px 0;
					border-bottom: 1px solid #000;
					display: none;
					li {
						padding: 12px 0;
						a {
							display: block;
							@include ffBebas;
							@include font(18,22);
							padding: 0 30px;
						}
					}
				}
			}
		}
	}
}
